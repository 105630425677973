<template>
  <div>
    <handle-modal
      ref="refHandleModal"
      @addQuestion="addQuestion"
    />

    <b-sidebar
      id="add-new-user-sidebar"
      :visible="isSidebarActive"
      :sidebar-class="{
        'expanded-menu': !isVerticalMenuCollapsed,
        'retracted-menu': isVerticalMenuCollapsed,
        'no-xl' : currentBreakPoint && currentBreakPoint !== 'xl'
      }"
      bg-variant="white"
      no-header
      z-index="10"
      right
      @hidden="resetForm"
      @change="(val) => $emit('update:is-sidebar-active', val)"
    >

      <template #default="{ hide }">
        <b-overlay
          :show="showOverlay"
          rounded="sm"
          no-fade
        >
          <!-- Header -->
          <div
            style="padding-top: 85px"
            class="d-flex content-sidebar-header"
          >
            <feather-icon
              class="cursor-pointer m-1"
              icon="ArrowLeftIcon"
              size="25"
              @click="hide"
            />
          </div>

          <div class="d-flex justify-content-between align-items-center px-2 py-1">
            <h5 class="mb-0">
              Novo Modelo de Anamnese
            </h5>
          </div>

          <div>
            <validation-observer
              #default="{invalid}"
              ref="refFormObserver"
            >
              <b-form
                class="p-2"
                @submit.prevent="onSubmit"
                @reset.prevent="resetForm"
              >
                <b-row>

                  <b-col
                    cols="12"
                    md="6"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="Nome do modelo"
                      rules="required"
                      immediate
                    >
                      <b-form-group
                        label="Nome do modelo"
                        label-for="model-name"
                      >
                        <b-form-input
                          id="model-name"
                          v-model="anamneseModelLocal.name"
                          :state="getValidationState(validationContext) ? null : false"
                          trim
                        />
                        <b-form-invalid-feedback>Obrigatório</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col
                    cols="12"
                    md="6"
                  >

                    <validation-provider
                      #default="validationContext"
                      name="observation"
                      rules="required"
                      immediate
                    >
                      <b-form-group
                        label="Descrição"
                        label-for="observation"
                      >
                        <b-form-input
                          id="observation"
                          v-model="anamneseModelLocal.observation"
                          :state="getValidationState(validationContext) ? null : false"
                          trim
                        />
                        <b-form-invalid-feedback>Obrigatório</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    cols="12"
                    md="8"
                  />
                  <b-col
                    cols="12"
                    md="4"
                    align="end"
                  >
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      class="mb-1"
                      @click="openModal"
                    >
                      Adicionar Pergunta
                    </b-button>
                  </b-col>
                  <b-col
                    cols="12"
                    md="12"
                  >

                    <draggable
                      v-model="anamneseModelLocal.questions"
                      class="list-group list-group-flush mt-2"
                      tag="ul"
                    >
                      <transition-group
                        type="transition"
                        name="flip-questions-list"
                      >
                        <b-list-group-item
                          v-for="(questionItem, idx) in anamneseModelLocal.questions"
                          :key="`questionItem_${idx}`"
                          tag="li"
                        >
                          <div class="d-flex align-items-center w-100 justify-content-between">

                            <div class="d-flex align-items-center">
                              <feather-icon
                                icon="MenuIcon"
                                class="cursor-move mr-2"
                              />
                              {{ questionItem.question }}
                            </div>

                            <div>
                              <span class="mr-2">
                                {{ questionItem.type === 'TEXT' ? 'Texto' : questionItem.type === 'RADIO' ? 'Sim e Não' : 'Sim e Não e Texto' }}
                              </span>
                              <b-button
                                variant="flat-info"
                                class="btn-icon"
                                @click="handleDelete(questionItem)"
                              >
                                <feather-icon
                                  size="18"
                                  icon="Trash2Icon"
                                />
                              </b-button>
                            </div>

                          </div>
                        </b-list-group-item>
                      </transition-group>
                    </draggable>

                  </b-col>
                </b-row>

                <!-- Form Actions -->
                <div class="d-flex mt-2">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="mr-2"
                    type="submit"
                    :disabled="invalid"
                  >
                    {{ anamneseModelLocal.id ? 'Alterar Modelo' : 'Criar Modelo' }}
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    type="button"
                    variant="outline-secondary"
                    @click="hide"
                  >
                    Cancelar
                  </b-button>
                </div>
              </b-form>
            </validation-observer>
          </div>
        </b-overlay>
      </template>

    </b-sidebar>
  </div>
</template>

<script>
import {
  BOverlay, BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback,
  BButton, BRow, BCol, BListGroupItem,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import draggable from 'vuedraggable'
import HandleModal from './HandleModal.vue'
import useAnamneseModel from '../useAnamneseModel'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BRow,
    BCol,
    BOverlay,
    BListGroupItem,

    ValidationProvider,
    ValidationObserver,
    draggable,
    HandleModal,

  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isSidebarActive',
    event: 'update:is-sidebar-active',
  },
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    anamneseModel: {
      type: Object,
      default: () => ({
        question: null,
        observation: null,
        questions: [],
      }),
    },
  },
  data() {
    return {
      required,
      mouseOver: null,
    }
  },
  computed: {
    isVerticalMenuCollapsed() {
      return this.$store.state.verticalMenu.isVerticalMenuCollapsed
    },
    currentBreakPoint() {
      return this.$store.getters['app/currentBreakPoint']
    },
  },
  setup(_, { emit, root }) {
    const {
      save,
      showOverlay,
    } = useAnamneseModel(emit)

    const blankData = {
      question: null,
      observation: null,
      questions: [],
    }
    const anamneseModelLocal = ref(JSON.parse(JSON.stringify(blankData)))

    const setAnamneseModelValues = data => {
      anamneseModelLocal.value = data
    }

    const resetData = () => {
      anamneseModelLocal.value = JSON.parse(JSON.stringify(blankData))
    }

    const addQuestion = data => {
      anamneseModelLocal.value.questions.push(
        {
          question: data.question,
          type: data.type,
        },
      )
    }

    const removeQuestion = idx => {
      anamneseModelLocal.value.questions.splice(idx, 1)
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetData)

    const anamneseQuestions = [
      { label: 'Pergunta', key: 'question' },
      { label: 'Tipo', key: 'type', thStyle: { width: '190px' } },
      { label: 'Ações', key: 'actions', thStyle: { width: '100px' } },

    ]

    const openModal = () => {
      emit('openModal')
    }

    const handleDelete = data => {
      root.$bvModal
        .msgBoxConfirm('Deseja excluir a pergunta?', {
          title: 'Por Favor Confirme esta Ação',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Sim',
          cancelTitle: 'Não',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(resp => {
          if (resp) removeQuestion(data.index)
        })
    }

    return {
      anamneseModelLocal,
      setAnamneseModelValues,
      anamneseQuestions,

      refFormObserver,
      getValidationState,
      resetForm,
      openModal,
      addQuestion,
      removeQuestion,
      save,
      showOverlay,
      handleDelete,
    }
  },
  methods: {
    async onSubmit() {
      this.save(this.anamneseModelLocal)
    },
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}

.expanded-menu {
  width: calc(100% - 288px);
  }

.retracted-menu {
  width: calc(100% - 108px);
  }

.no-xl {
  width: calc(100% - 28px);
}
// resolve a cor do overlay
.dark-layout {
  div ::v-deep {
    .b-overlay {
      .bg-light {
        background-color: $theme-dark-body-bg !important;
      }
    }
  }
}

</style>
