<template>

  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="2"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Exibir</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="8"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block"
                placeholder="Buscar..."
                debounce="500"
              />

            </div>
          </b-col>

          <b-col
            cols="12"
            md="2"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                variant="primary"
                block
                @click="isSidebarActive = true"
              >
                Novo Modelo
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-overlay
        :show="showOverlay"
        rounded="sm"
        no-fade
      >
        <b-table
          ref="refTable"
          class="position-relative tableMinHeght"
          :items="fetch"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="Nenhum resultado encontrado"
          :sort-desc.sync="isSortDirDesc"
          no-sort-reset
        >

          <!-- Column: dental_plans -->
          <template #cell(dental_plans)="data">

            <b-list-group>
              <div
                v-for="dentalPlan in data.item.dental_plans"
                :key="dentalPlan.id"
                class="d-flex"
              >
                <span class="mr-1">
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="16"
                  />
                </span>
                <span>{{ dentalPlan.name }}</span>
              </div>

            </b-list-group>

          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >

              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>

              <b-dropdown-item @click="handleEdit(data.item)">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Editar</span>
              </b-dropdown-item>

              <b-dropdown-item @click="handleDelete(data.item)">
                <feather-icon icon="TrashIcon" />
                <span
                  class="align-middle ml-50"
                >Excluir</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>

        </b-table>
      </b-overlay>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Exibindo {{ dataMeta.from }} a {{ dataMeta.to }} de {{ dataMeta.of }} entradas</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalData"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
    <anamnese-model-add-new
      ref="refAnamneseAddNewModal"
      :is-sidebar-active.sync="isSidebarActive"
      :anamnese-model="anamneseModel"
      @openModal="openModal"
      @refetchData="refetchData"
    />
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BTable, BDropdown, BDropdownItem, BPagination, BButton, BListGroup, BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref, onUnmounted } from '@vue/composition-api'
import store from '@/store'
// eslint-disable-next-line import/no-cycle
import useAnamneseModel from '../useAnamneseModel'
import useStoreAnamneseModels from '../useStoreAnamneseModels'
import AnamneseModelAddNew from './AnamneseModelAddNew.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    BButton,
    BListGroup,
    BOverlay,

    AnamneseModelAddNew,

    vSelect,
  },
  setup(props, { root }) {
    const ANAMNESE_MODEL_STORE_MODULE_NAME = 'store-anamnese-model'

    // Register module
    if (!store.hasModule(ANAMNESE_MODEL_STORE_MODULE_NAME)) store.registerModule(ANAMNESE_MODEL_STORE_MODULE_NAME, useStoreAnamneseModels)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ANAMNESE_MODEL_STORE_MODULE_NAME)) store.unregisterModule(ANAMNESE_MODEL_STORE_MODULE_NAME)
    })

    const {
      tableColumns,
      perPage,
      currentPage,
      totalData,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refTable,
      isSidebarActive,
      showOverlay,

      refetchData,
      fetch,
      deleteData,
    } = useAnamneseModel()

    const refHandleModal = ref(null)

    const anamneseModel = ref({})

    const refAnamneseAddNewModal = ref(null)

    const handleEdit = data => {
      anamneseModel.value = data
      refAnamneseAddNewModal.value.setAnamneseModelValues(data)
      isSidebarActive.value = true
    }

    const openModal = () => {
      root.$bvModal.show('handle-modal')
    }

    const handleDelete = data => {
      root.$bvModal
        .msgBoxConfirm(`Deseja Excluir o modelo ${data.name}?`, {
          title: 'Por Favor Confirme esta Ação',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Sim',
          cancelTitle: 'Não',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) deleteData(data)
        })
    }

    return {
      tableColumns,
      perPage,
      currentPage,
      totalData,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refTable,
      refHandleModal,
      isSidebarActive,
      anamneseModel,
      showOverlay,

      refAnamneseAddNewModal,

      fetch,
      handleEdit,
      handleDelete,
      refetchData,
      deleteData,
      openModal,
    }
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  .codeTdClass {
    width: 150px;
  }
  .statusTdClass {
    width: 120px;
  }
  .actionsTdClass {
    width: 120px;
  }
  // resolve a cor do overlay
  .dark-layout {
  div ::v-deep {
    .b-overlay {
      .bg-light {
        background-color: $theme-dark-body-bg !important;
      }
    }
  }
}
</style>
