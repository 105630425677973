<template>
  <div>

    <validation-observer
      #default="{invalid}"
      ref="refFormObserver"
    >
      <b-modal
        id="handle-modal"
        v-model="modalHandleShow"
        centered
        size="lg"
        title="Nova Pergunta"
        @hidden="resetForm"
      >

        <!-- Table Container Card -->
        <b-card
          no-body
          class="mb-0"
        >

          <b-form class="pl-2 pr-2">

            <b-row>

              <b-col
                cols="12"
                md="12"
                class="mt-1"
              >

                <validation-provider
                  #default="validationContext"
                  name="question"
                  rules="required"
                  immediate
                >
                  <b-form-group
                    label="Pergunta"
                    label-for="question-id"
                  >
                    <b-form-input
                      id="question-id"
                      v-model="question.question"
                      :state="getValidationState(validationContext) ? null : false"
                      trim
                    />
                    <b-form-invalid-feedback>Obrigatório</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col
                cols="12"
                md="12"
                class="pb-1"
              >
                <h6 style="font-size: 0.857rem">
                  Tipo de pergunta
                </h6>
                <validation-provider
                  #default="validationContext"
                  name="type-of-question"
                  rules="required"
                  immediate
                >
                  <b-form-radio-group
                    v-model="question.type"
                    :state="getValidationState(validationContext) ? null : false"
                    :options="types"
                    label="Tipo de Resposta"
                    label-for="type-of-question-id"
                  >
                    <b-form-invalid-feedback>Obrigatório</b-form-invalid-feedback>
                  </b-form-radio-group>
                </validation-provider>
              </b-col>
            </b-row>
          </b-form>

        </b-card>
        <template #modal-footer="{ cancel }">
          <b-button
            variant="primary"
            :disabled="invalid"
            @click="$emit('addQuestion', question); modalHandleShow = false"
          >
            Adicionar
          </b-button>
          <b-button
            variant="outline-secondary"
            @click="cancel()"
          >
            Cancelar
          </b-button>
        </template>

      </b-modal>
    </validation-observer>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormInput, BButton, BCard, BForm, BFormGroup, BFormInvalidFeedback,
  BFormRadioGroup, /* BFormRadio, */
} from 'bootstrap-vue'
import {
  required,
} from '@validations'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { ref } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'

export default {
  components: {
    BRow,
    BCol,
    BFormInput,
    BButton,
    BCard,
    BForm,
    BFormGroup,
    BFormInvalidFeedback,
    BFormRadioGroup,
    // BFormRadio,

    // Form Validation
    ValidationProvider,
    ValidationObserver,

  },

  setup() {
    localize('pt_BR') // vee-validate messages

    const modalHandleShow = ref(false)

    const blankData = {
      question: null,
      type: 'RADIO',
    }
    const question = ref(JSON.parse(JSON.stringify(blankData)))
    const resetFormData = () => {
      question.value = JSON.parse(JSON.stringify(blankData))
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetFormData)

    const fillData = data => {
      question.value = data
    }

    const type = ref('RADIO')

    const types = [
      { text: 'Sim ou não', value: 'RADIO' },
      { text: 'Texto', value: 'TEXT' },
      { text: 'Ambos', value: 'RADIO_TEXT' },
    ]

    return {
      required,
      modalHandleShow,
      types,
      type,

      getValidationState,
      resetForm,
      fillData,
      refFormObserver,
      question,
    }
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  .selectAllTdClass {
    width: 50px;
  }
  .codeTdClass {
    width: 206px;
  }
  .unitTdClass {
    width: 140px;
  }
  .priceTdClass {
    width: 206px;
  }
</style>
